import React from 'react'

export default function Careers() {
  return (
    <div>
      <h1>Careers</h1>
      <p>Interested in join team? Here is a list of job openings at Nebula Core Technologies</p>
    </div>
  )
}
